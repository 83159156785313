import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from "react-router-dom"
import axios from 'axios'
import { BsFillCalendarPlusFill, BsFillCalendarMinusFill } from 'react-icons/bs';
import { FcLike } from 'react-icons/fc';
import moment from 'moment';
import Swal from 'sweetalert2'
import ReactHlsPlayer from 'react-hls-player'
import { Helmet } from 'react-helmet'

const PlaceSingle = () => {
    const token = localStorage.getItem("_jwt");
    const [isLoading, setIsLoading] = useState(0);
    let history = useNavigate();
    const navigate = useNavigate();
    const [id, setId] = useState(useParams().id)
    const [postType, setpostType] = useState('');
    const [postTypes, setpostTypes] = useState('');
    const [name, setname] = useState('');
    const [description, setdescription] = useState('');
    const [createdAt, setcreatedAt] = useState('');
    const [updatedAt, setupdatedAt] = useState('');
    const [username, setusername] = useState('');
    const [profilePic, setprofilePic] = useState('');
    const [mainImage, setmainImage] = useState('');
    const [event_date_start, setevent_date_start] = useState('');
    const [event_date_stop, setevent_date_stop] = useState('');
    const [mainImageID, setmainImageID] = useState('');
    const [likes, setlikes] = useState(0);
    const [vids, setvids] = useState([]);
    const [files, setfiles] = useState([]);
    const [audios, setaudios] = useState([]);
    const [images, setimages] = useState([]);

    let newDatecreatedAt = moment.utc(createdAt).format('DD/MM/YYYY HH:mm:ss');
    let event_date_start_human = moment.utc(event_date_start).format('DD/MM/YYYY HH:mm:ss');
    let event_date_stop_human = moment.utc(event_date_stop).format('DD/MM/YYYY HH:mm:ss');

    const fetchData = async () => {
        axios.get(`https://etnoserver.pl:44444/api/places/show/${id}`)
            .then(function (response) {
                let place = response.data
                setpostType(place.postType == 'event' ? 'Wydarzenie' : 'Publikacja');
                setpostTypes(place.postType);
                setevent_date_start(place.event_date_start);
                setevent_date_stop(place.event_date_stop);
                setname(place.name);
                setdescription(place.description);
                setcreatedAt(place.createdAt);
                setupdatedAt(place.updatedAt);
                setusername(place.user?.name);
                setprofilePic(place.user?.profilePic);
                setfiles(place.files);
                setvids(place.vids);
                setimages(place.images);
                setlikes(place.likes.length);
                setmainImage(place.mainImage?.fileName);
                setmainImageID(place.mainImage?.id);

            })
            .catch(function (error) {

            })
    }

    useEffect(() => {
        setIsLoading(1)
        axios.get(`https://etnoserver.pl:44444/api/places/show/${id}`)
            .then(function (response) {
                let place = response.data
                setpostType(place.postType == 'event' ? 'Wydarzenie' : 'Publikacja');
                setpostTypes(place.postType);
                setevent_date_start(place.event_date_start);
                setevent_date_stop(place.event_date_stop);
                setname(place.name);
                setdescription(place.description);
                setcreatedAt(place.createdAt);
                setupdatedAt(place.updatedAt);
                setusername(place.user?.name);
                setprofilePic(place.user?.profilePic);
                setfiles(place.files);
                setvids(place.vids);
                setimages(place.images);
                setlikes(place.likes.length);
                setmainImage(place.mainImage?.fileName);
                setmainImageID(place.mainImage?.id);

            })
            .catch(function (error) {

            })

    }, [])

    const removemedia = (mainImageID) => {
        Swal.fire({
            icon: 'question',
            title: 'Czy na pewno?',
            text: 'Niniejszym potwierdzasz usunięcie tej grafiki.',
            confirmButtonText: 'Potwierdzam',
            showCancelButton: true,
            cancelButtonText: 'Anuluj',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`https://etnoserver.pl:44444/api/files/delete/${mainImageID}`, {}, {
                    headers: {
                        ['x-access-token']: token,
                    },
                  })
                    .then(function (response) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Grafika została usunięta z sukcesem',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        fetchData()
                    })
                    .catch(function (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'An Error Occured!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    });
            }
        })
    }

    const removepost = (id) => {
        Swal.fire({
            icon: 'question',
            title: 'Czy na pewno?',
            text: 'Niniejszym potwierdzasz usunięcie tego wpisu wraz z plikami do niego wgranych.',
            confirmButtonText: 'Potwierdzam',
            showCancelButton: true,
            cancelButtonText: 'Anuluj',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`https://etnoserver.pl:44444/api/places/delete/${id}`, {}, {
                    headers: {
                        ['x-access-token']: token,
                    },
                  })
                    .then(function (response) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Wpis został usunięty. Trwa przenoszenie na stronę główną kokpitu.',
                            showConfirmButton: false,
                            timer: 3000
                        })
                        setTimeout(function () {
                            navigate("/places");
                        }, 3000);
                    })
                    .catch(function (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'An Error Occured!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    });
            }
        })
    }
    return (
        <>
        <Helmet>
            <title>{name}</title>
        </Helmet>
        <div className={isLoading === 0 ?  'lds-hourglass'  : null}></div>
            <div className="col-md-10 mx-auto">
                <button className="btn btn-danger ml-3 mt-3 back" onClick={() => history(-1)}>Powrót</button>
                <div className="archive-card-big p-0">
                    <div className='container-fluid p-0'>
                        {mainImageID ?
                        <button className="btn btn-danger ml-3 mt-3 removemainimage" onClick={() => removemedia(mainImageID)}>Usuń obrazek główny</button>
                        : null}
                        <button className="btn btn-danger ml-3 removemaplace" onClick={() => removepost(id)}>Usuń ten wpis</button>
                        <div className="archive-header">
                            <img src={mainImage ? 'https://etnoserver.pl/uploads/' + mainImage : 'https://citelis.com.mx/assets/images/edificios-independientes/Cover/EIMLM145.png'} alt="" />
                        </div>
                        <div className='cont pt-4 pr-5 pl-5 pb-3'>
                            <div className="d-flex justify-content-between name">
                                <div>{postType}: <strong>{name ? name : 'Unlocalized String'}</strong><br /></div>
                            </div>
                            <div className='profile d-block'>
                                <div className='d-flex justify-content-between profile'>
                                    <div className='d-flex'>
                                        <img className='profileimg' src={'https://etnoserver.pl/uploads/' + profilePic} onError={(event) => event.target.style.display = 'none'} onLoad={(event) => event.target.style.display = ''} alt="" />
                                        <h5>Opublikował/a: <strong>{username}</strong> w dniu <strong>{newDatecreatedAt}</strong></h5>
                                    </div>
                                    <div className='like'><FcLike className='likes' /> {likes} polubień</div>
                                </div>
                            </div>
                            {postTypes == 'event' ?
                                <>
                                    <div className='mt-4 mb-3'><BsFillCalendarPlusFill /> Rozpoczęcie: <strong>{event_date_start_human}</strong></div>
                                    <div className='mb-2'><BsFillCalendarMinusFill /> Zakończenie: <strong>{event_date_stop_human}</strong></div>
                                </>
                                : null
                            }
                            
                            <h4 className='mt-4 bold'><strong>Opis</strong></h4>
                            <p className='mb-5'>{description}</p>

                            {images ? 
                            <>
                            <h4 className='mt-4 bold'><strong>Galeria</strong></h4>
                            <div className='imggall justify-content-between mt-3 row'>
                                {images.map((images, index) => {
                                    return (
                                        <div className='col-md-4 p-0 main-image' key={index}>
                                            <img className='w-100' src={images.fileName ? 'https://etnoserver.pl/uploads/' + images.fileName : ''} />
                                            <button className='imgremove' onClick={() => removemedia(images.id)}>x</button>
                                        </div>
                                    )
                                })}
                            </div>
                            </>
                            : 'brak'
                            }
                            {audios ?
                            <>
                            <h4 className='mt-4 bold'><strong>Filmy</strong></h4>
                            <div className='row'>
                            {audios.map((audios, index) => {
                                return (                                    
                                    <div className='col-md-4 p-0' key={index}>
                                        <audio controls="true" width="100%" height="auto" src={'https://etnoserver.pl/uploads/' + audios.fileName} ></audio>
                                        <button className='imgremove' onClick={() => removemedia(audios.id)}>x</button>
                                    </div>
                                )
                            })}
                            </div>
                            </>
                            : 'brak'
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default PlaceSingle;