import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'
import axios from 'axios'
import ReactPaginate from 'react-paginate'
import { Link } from "react-router-dom"

const Report = () => {
    const token = localStorage.getItem("_jwt");

    const [display, setDisplay] = useState(0);
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(4);
    const [pageCount, setPageCount] = useState(0)
    const [UserList, setUserList] = useState([])
    const [ReportList, SetReportList] = useState(0)
    const [UserID, SetUserID] = useState(0)
    const [PostID, SetPostID] = useState(0)

    var pathname = window.location.pathname
    var match = pathname.match(/\/users\/page\/(\d*)/)
    if (match) {
        var x = (match[1] - 1)
    }

    const fetchData = async () => {
        const response = await fetch('https://etnoserver.pl:44444/api/reports', {
            headers: {
                ['x-access-token']: token,
            }
        })
        const nbaData = await response.json()
        setPageCount(Math.ceil(nbaData.length / perPage))
        setUserList(nbaData.slice(offset, offset + perPage))
        var pathname = window.location.pathname
        var match = pathname.match(/\/users\/page\/(\d*)/)
        if (match) {
            setOffset((match[1] - 1) * perPage)
        }
        if (nbaData.slice(offset, offset + perPage).length === 0) {
            setOffset((match[1] - 2) * perPage)
            // window.history.replaceState(null, '', 'http://' + window.location.hostname + ':' + window.location.port + '/users/page/' + (match[1] - 1));
        }
    }


    const fetchDatabyID = async (postid) => {
        setDisplay(1)
        axios.get(`https://etnoserver.pl:44444/api/report/${postid}`, {
            headers: {
                ['x-access-token']: token,
            }
        })
            .then(function (response) {
                let user = response.data
                // setEmail(user.email);
                SetReportList(user)

                SetPostID(user.placeId)
                SetUserID(user.authorId)

                axios.get(`https://etnoserver.pl:44444/api/user/${user.authorId}`)
                    .then(function (response) {
                        let user = response.data
                        SetUserID(user)
                    })
                    .catch(function (error) {

                    })

                axios.get(`https://etnoserver.pl:44444/api/places/show/${user.placeId}`)
                    .then(function (response) {
                        let user = response.data
                        SetPostID(user)
                    })
                    .catch(function (error) {

                    })

            })
            .catch(function (error) {

            })
    }

    useEffect(() => {
        fetchData()
    }, [offset])

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setOffset((selectedPage) * perPage)
        // window.history.replaceState(null, '', 'http://' + window.location.hostname + ':' + window.location.port + '/users/page/' + (selectedPage + 1));
    }
    return (
        <>
            <Helmet>
                <title>Zgłoszenia</title>
            </Helmet>
            <div className="d-sm-flex align-items-center justify-content-between">
                <h1 className="h2 mb-3 text-gray-800">Zgłoszenia i pomoc</h1>
            </div>
            <div className="d-sm-flex justify-content-between">
                <div className="col-md-4 list pl-0">
                    {UserList.map((user, key) => {
                        return (
                            <div key={key} className={user.is_read === 0 ? 'post text-left mt-0 border border-danger' : 'post text-left mt-0'}>
                                <div className="content p-4">
                                    <div className="d-flex">
                                        <img className="profileimg shadow-none" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c6/Exclamation_Circle_Red.svg/768px-Exclamation_Circle_Red.svg.png" alt="" />
                                        <div>
                                            <h4>
                                            {user.type === 'report'
                                                ? 
                                                <>
                                                Zgłoszenie #{user.id}
                                                </>
                                                :
                                                <>
                                                Pomoc #{user.id}
                                                </>
                                            }
                                                
                                            </h4>
                                            <small>Opublikowano: {user.createdAt}</small>
                                            <p>{user.content ? user.content.substring(0, 100) + '...' : null}</p>
                                        </div>
                                    </div>
                                    <a className="btn btn-primary mt-3 text-right" onClick={() => [fetchDatabyID(user.id)]}>Szczegóły</a>
                                </div>
                            </div>
                        )
                    })

                    }

                    <ul className="pagination m-0 pt-2 pb-4 me-auto w-100">
                        <ReactPaginate
                            previousLabel="Poprzednia"
                            nextLabel="Następna"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={10}
                            onPageChange={handlePageClick}
                            forcePage={x}
                            containerClassName="pagination container-fluid text-end p-0"
                            activeClassName="active"
                        />
                    </ul>


                </div>

                {display == 1 ?
                    <>
                        <div className="element col-md-8 post m-0 p-4 text-left">
                            <div className="d-flex">
                                <img className="profileimg shadow-none" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c6/Exclamation_Circle_Red.svg/768px-Exclamation_Circle_Red.svg.png" alt="" />
                                <div className="">
                                    {/* <h4>{ReportList.author?.name}</h4> */}
                                    <h2 className='font-weight-bold'>
                                        {ReportList.type === 'report'
                                                ? 
                                                <>
                                                Zgłoszenie #{ReportList.id}
                                                </>
                                                :
                                                <>
                                                Pomoc #{ReportList.id}
                                                </>
                                            }
                                    </h2>
                                    <small>Opublikowano: {ReportList.createdAt}</small>
                                </div>
                            </div>
                            <div className="content mt-3">
                                <p>{ReportList.content}</p>
                            </div>
                            <hr />
                            <div className="content cbr mt-3">
                                <div className="d-flex justify-content-between">
                                    {PostID ?
                                    <>
                                    <div className='col-md-6 p-0'>
                                        <h5>Zgłoszenie dotyczy posta:</h5>
                                        <div className="mt-3 p-0 col-md-10">
                                            <div className="card">
                                                <img src={PostID.mainImage ? 'https://etnoserver.pl/uploads/' + PostID.mainImage?.fileName : 'https://citelis.com.mx/assets/images/edificios-independientes/Cover/EIMLM145.png'} className="card-img-top" alt="..." />
                                                <div className="card-body">
                                                    <h5 className="card-title font-weight-bold">{ReportList.place?.name}</h5>
                                                    <p className="card-text">{ReportList.place?.description ? ReportList.place?.description.substring(0, 100) + '...' : null}...</p>
                                                    <Link className="btn btn-primary mt-3" to={`/archive/${ReportList.place?.id}`}>Przejdź do posta</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    : null
                                    }
                                    {UserID ? 
                                    <>
                                    <div className='col-md-6 p-0'>
                                        <h5>Zgłaszający:</h5>
                                        <div className="mt-3 p-0 col-md-10">
                                            <div className="card">
                                                <img src={UserID.profileBigPic ? 'https://etnoserver.pl/uploads/' + UserID.profileBigPic?.fileName : 'https://citelis.com.mx/assets/images/edificios-independientes/Cover/EIMLM145.png'} className="card-img-top" alt="..." />
                                                <div className="author-img"><img src={UserID.profilePic?.fileName ? 'https://etnoserver.pl/uploads/' + UserID.profilePic?.fileName : 'https://i2.wp.com/a.slack-edge.com/df10d/img/avatars/ava_0007-192.png?ssl=1'} alt="" /></div>
                                                <div className="card-body">
                                                    <h5 className="card-title mt-3 font-weight-bold">{ReportList.author?.name}</h5>
                                                    <p className="card-text">{ReportList.author?.profileDescription ? ReportList.author?.profileDescription.substring(0, 100) + '...' : null}</p>
                                                    <Link className="btn btn-primary mt-3" to={`/user/${ReportList.author?.id}`}>Szczegóły</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    : null
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                    : null
                }
            </div>
        </>);
};

export default Report;