import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from "react-router-dom"
import axios from 'axios'
import { CgFacebook } from 'react-icons/cg';
import { BsTwitter } from 'react-icons/bs';
import { MdOutlineMail } from 'react-icons/md';
import { AiOutlineEye } from 'react-icons/ai';
import { RiDraftFill, RiArticleFill, RiQuestionLine } from 'react-icons/ri';
import ReactPaginate from 'react-paginate'
import moment from 'moment';
import { Helmet } from 'react-helmet'

const UserFriends = () => {
    //user
    const [isLoading, setIsLoading] = useState(0);
    let history = useNavigate();
    const [id, setId] = useState(useParams().id)
    const [email, setEmail] = useState('');
    const [name, setName] = useState('Ładuję!');
    const [username, setUsername] = useState('');
    const [profileBigPic, setprofileBigPic] = useState('');
    const [profilePic, setprofilePic] = useState('');
    const [profileDescription, setprofileDescription] = useState('');
    const [fbLink, setfbLink] = useState('');
    const [twitterLink, settwitterLink] = useState('');
    const [emailLink, setemailLink] = useState('');
    const [phone, setphone] = useState('');
    const [address, setaddress] = useState('');
    const [www, setwww] = useState('');
    const [createdAt, setcreatedAt] = useState('');
    const [updatedAt, setupdatedAt] = useState('');
    const [googleId, setgoogleId] = useState('');
    const [fbId, setfbId] = useState('');
    const [otherWatches, setotherWatches] = useState([]);
    const [myWatches, setmyWatches] = useState([]);

    let newDatecreatedAt = moment.utc(createdAt).format('DD/MM/YYYY HH:mm:ss');
    let newDateupdatedAt = moment.utc(updatedAt).format('DD/MM/YYYY HH:mm:ss');

    useEffect(() => {
        setIsLoading(1)
        axios.get(`https://etnoserver.pl:44444/api/user/${id}`)
            .then(function (response) {
                let user = response.data
                setEmail(user.email);
                setName(user.name);
                setUsername(user.username);
                setprofileBigPic(user.profileBigPic?.fileName);
                setprofilePic(user.profilePic?.fileName);
                setprofileDescription(user.profileDescription);
                setfbLink(user.fbLink);
                settwitterLink(user.twitterLink);
                setaddress(user.address);
                setwww(user.www);
                setcreatedAt(user.createdAt);
                setupdatedAt(user.updatedAt);
                setgoogleId(user.googleId);
                setfbId(user.fbId);
                setemailLink(user.emailLink);
                setphone(user.phone);
                setotherWatches(user.otherWatches);
                setmyWatches(user.myWatches);
                

            })
            .catch(function (error) {

            })

    }, [])

    return (
        <>
        <Helmet>
            <title>Obserwujący {name ? name : 'Nieznany użytkownik'}</title>
        </Helmet>
        <div class={isLoading === 0 ?  'lds-hourglass'  : null}></div>
            <div className="col-md-10 mx-auto">
                <button className="btn btn-danger ml-3 mt-3 back" onClick={() => history(-1)}>Powrót</button>
                <div className="profile-card-big p-0">
                    <div className='container-fluid p-0'>
                        <div className="profile-header">
                            <img src={profileBigPic ? 'https://etnoserver.pl/uploads/' + profileBigPic : 'https://citelis.com.mx/assets/images/edificios-independientes/Cover/EIMLM145.png'} alt="" />
                        </div>
                        <div className="profile-body">
                            <div className="author-img d-flex">
                                <img src={profilePic ? 'https://etnoserver.pl/uploads/' + profilePic : 'https://i2.wp.com/a.slack-edge.com/df10d/img/avatars/ava_0007-192.png?ssl=1'} alt="" />
                                <div className="d-block">
                                    <div className="name">
                                        {name ? name : 'Nieznany użytkownik'}<br />
                                    </div>
                                    {email ? email : emailLink} <strong>{googleId ? '(Zalogowano kontem Google)' : ''} {fbLink ? '(Zalogowano kontem Facebook)' : ''}</strong>
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex'>
                                            {fbLink ? <a className='profile-card-social__item m-0 mt-3 mr-2 facebook' href={fbLink}> <CgFacebook /></a> : ''}
                                            {twitterLink ? <a className='profile-card-social__item m-0 mt-3 mr-2 twitter' href={twitterLink}> <BsTwitter /></a> : ''}
                                            {emailLink ? <a className='profile-card-social__item m-0 mt-3 mr-2 link' href={emailLink}> <MdOutlineMail /></a> : ''}
                                        </div>
                                        <div className='d-flex'>
                                            <Link className="btn btn-primary p10px mt-4 mr-3" to={`/user/${id}/watches`}><AiOutlineEye /> Obserwujący ({otherWatches.length})</Link>
                                            <Link className="btn btn-primary p10px mt-4" to={`/user/${id}/watches/my`}><AiOutlineEye /> Obserwowani ({myWatches.length})</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-3 container-fluid p-0 m-0'>
                    <div className='col-md-12 p-0'>

                        <div className="profile-card-small p-4 text-left">
                            <h3>Obserwujący</h3>
                            <div className='row'>
                                {myWatches.map((myWatches, index) => {

                                    return (

                                        <div className="watches text-left mt-3 col-md-4" key={index}>
                                            <div className='content pt-3'>
                                                <Link to={`/user/${myWatches?.watchedUser}/`}>
                                                    <div className='d-flex'>
                                                        <div className=''>
                                                            <img className='profile' src={myWatches.author?.profilePic ? 'https://etnoserver.pl/uploads/' + myWatches.author?.profilePic : 'https://i2.wp.com/a.slack-edge.com/df10d/img/avatars/ava_0007-192.png?ssl=1'} onError={(event) => event.target.style.display = 'none'} onLoad={(event) => event.target.style.display = ''} alt="" />
                                                        </div>
                                                        <div className=''>
                                                            <h4>{myWatches.watched_name}</h4>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>


                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default UserFriends;