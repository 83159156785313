import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from "react-router-dom"
import axios from 'axios'
import { CgFacebook } from 'react-icons/cg';
import { BsTwitter } from 'react-icons/bs';
import { MdOutlineMail } from 'react-icons/md';
import { AiOutlineEye } from 'react-icons/ai';
import { RiDraftFill, RiArticleFill, RiQuestionLine } from 'react-icons/ri';
import ReactPaginate from 'react-paginate'
import moment from 'moment';
import Swal from 'sweetalert2'
import { Helmet } from 'react-helmet'

const UserInfo = () => {
    //user
    const token = localStorage.getItem("_jwt");
    const [isLoading, setIsLoading] = useState(0);
    const navigate = useNavigate();
    let history = useNavigate();
    const [id, setId] = useState(useParams().id)
    const [firstS, setfirstS] = useState(1);
    const [secondS, setsecondS] = useState(0);
    const [type, settype] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [profileBigPic, setprofileBigPic] = useState('');
    const [profilePic, setprofilePic] = useState('');
    const [profileDescription, setprofileDescription] = useState('');
    const [fbLink, setfbLink] = useState('');
    const [twitterLink, settwitterLink] = useState('');
    const [emailLink, setemailLink] = useState('');
    const [phone, setphone] = useState('');
    const [address, setaddress] = useState('');
    const [www, setwww] = useState('');
    const [createdAt, setcreatedAt] = useState('');
    const [updatedAt, setupdatedAt] = useState('');
    const [googleId, setgoogleId] = useState('');
    const [fbId, setfbId] = useState('');
    const [otherWatches, setotherWatches] = useState('');
    const [myWatches, setmyWatches] = useState('');
    const [myGallery, setmyGallery] = useState([]);
    let newDatecreatedAt = moment.utc(createdAt).format('DD/MM/YYYY HH:mm:ss');
    let newDateupdatedAt = moment.utc(updatedAt).format('DD/MM/YYYY HH:mm:ss');

    const removemedia = (postid) => {
        Swal.fire({
            icon: 'question',
            title: 'Czy na pewno?',
            text: 'Niniejszym potwierdzasz usunięcie tego wpisów, a także mediów z nim związane.',
            confirmButtonText: 'Potwierdzam',
            showCancelButton: true,
            cancelButtonText: 'Anuluj',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`https://etnoserver.pl:44444/api/places/delete/${postid}`, {}, {
                    headers: {
                        ['x-access-token']: token,
                    },
                  })
                    .then(function (response) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Wpis został usunięty z sukcesem',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        fetchData()
                    })
                    .catch(function (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'An Error Occured!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    });
            }
        })
    }

    useEffect(() => {
        setIsLoading(1)
        axios.get(`https://etnoserver.pl:44444/api/user/${id}`)
            .then(function (response) {
                let user = response.data
                setEmail(user.email);
                setName(user.name);
                setUsername(user.username);
                setprofileBigPic(user.profileBigPic?.fileName);
                setprofilePic(user.profilePic?.fileName);
                setprofileDescription(user.profileDescription);
                setfbLink(user.fbLink);
                settwitterLink(user.twitterLink);
                setaddress(user.address);
                setwww(user.www);
                setcreatedAt(user.createdAt);
                setupdatedAt(user.updatedAt);
                setgoogleId(user.googleId);
                setfbId(user.fbId);
                setemailLink(user.emailLink);
                setphone(user.phone);
                setotherWatches(user.otherWatches);
                setmyWatches(user.myWatches);
                setmyGallery(user.images);

            })
            .catch(function (error) {

            })

    }, [])


    const [offset, setOffset] = useState(0);
    const [perPage] = useState(5);
    const [pageCount, setPageCount] = useState(0)
    const [pageTitle, setpageTitle] = useState('NULL')
    const [UserList, setUserList] = useState([])

    const fetchData_3 = async () => {
        const response = await fetch(`https://etnoserver.pl:44444/api/question/${id}/questionedUser`)
        const nbaData = await response.json()
        setPageCount(Math.ceil(nbaData.length / perPage))
        setUserList(nbaData.slice(offset, offset + perPage))
        setpageTitle('Pytania')
        setfirstS(0)
        setsecondS(1)
    }

    useEffect(() => {
        fetchData_3()
    }, [offset])

    const fetchData_2 = async () => {
        const response = await fetch(`https://etnoserver.pl:44444/api/places/userIndex/${id}/draft/1/type/pub`)
        const nbaData = await response.json()
        setPageCount(Math.ceil(nbaData.length / perPage))
        setUserList(nbaData.slice(offset, offset + perPage))
        setpageTitle('Szkice')
        setfirstS(1)
        setsecondS(0)
        settype('nie utworzył/a szkców.')
    }

    useEffect(() => {
        fetchData_2()
    }, [offset])

    const fetchData = async () => {
        const response = await fetch(`https://etnoserver.pl:44444/api/places/userIndex/${id}/draft/0/type/pub`)
        const nbaData = await response.json()
        setPageCount(Math.ceil(nbaData.length / perPage))
        setUserList(nbaData.slice(offset, offset + perPage))
        setpageTitle('Opublikowane archiwa')
        setfirstS(1)
        setsecondS(0)
        settype('niczego nie opublikował/a.')
    }

    useEffect(() => {
        fetchData()
    }, [offset])

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setOffset((selectedPage) * perPage)
    }


    const removeuser = (id) => {
        Swal.fire({
            icon: 'question',
            title: 'Czy na pewno?',
            text: 'Niniejszym potwierdzasz usunięcie tego użytkownika wraz z jego wpisami.',
            confirmButtonText: 'Potwierdzam',
            showCancelButton: true,
            cancelButtonText: 'Anuluj',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`https://etnoserver.pl:44444/api/user/delete/${id}`, {}, {
                    headers: {
                        ['x-access-token']: token,
                    },
                  })
                    .then(function (response) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Użytkowanik został usunięty. Trwa przenoszenie na stronę główną kokpitu.',
                            showConfirmButton: false,
                            timer: 3000
                        })
                        setTimeout(function () {
                            navigate("/users");
                        }, 3000);
                    })
                    .catch(function (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'An Error Occured!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    });
            }
        })
    }

    return (

        <>
            <Helmet>
                <title>{name ? name : 'Nieznany użytkownik'}</title>
            </Helmet>
            <div class={isLoading === 0 ? 'lds-hourglass' : null}></div>
            <div className="col-md-10 mx-auto">
                <button className="btn btn-danger ml-3 mt-3 back" onClick={() => history(-1)}>Powrót</button>
                <div className="profile-card-big p-0">
                    <div className='container-fluid p-0'>
                        <button className='btn btn-danger ml-3 mt-3 removemainimage' onClick={() => removeuser(id)}>Usuń użytkownika</button>
                        <div className="profile-header">
                            <img src={profileBigPic ? 'https://etnoserver.pl/uploads/' + profileBigPic : 'https://citelis.com.mx/assets/images/edificios-independientes/Cover/EIMLM145.png'} alt="" />
                        </div>
                        <div className="profile-body">
                            <div className="author-img d-flex">
                                <img src={profilePic ? 'https://etnoserver.pl/uploads/' + profilePic : 'https://i2.wp.com/a.slack-edge.com/df10d/img/avatars/ava_0007-192.png?ssl=1'} alt="" />
                                <div className="d-block">
                                    <div className="name">
                                        {name ? name : 'Nieznany użytkownik'}<br />
                                    </div>
                                    {email ? email : emailLink} <strong>{googleId ? '(Zalogowano kontem Google)' : ''} {fbLink ? '(Zalogowano kontem Facebook)' : ''}</strong>
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex'>
                                            {fbLink ? <a className='profile-card-social__item m-0 mt-3 mr-2 facebook' href={fbLink}> <CgFacebook /></a> : ''}
                                            {twitterLink ? <a className='profile-card-social__item m-0 mt-3 mr-2 twitter' href={twitterLink}> <BsTwitter /></a> : ''}
                                            {emailLink ? <a className='profile-card-social__item m-0 mt-3 mr-2 link' href={emailLink}> <MdOutlineMail /></a> : ''}
                                        </div>
                                        <div className='d-flex'>
                                            <Link className="btn btn-primary p10px mt-4 mr-3" to={`/user/${id}/watches`}><AiOutlineEye /> Obserwujący ({otherWatches.length})</Link>
                                            <Link className="btn btn-primary p10px mt-4" to={`/user/${id}/watches/my`}><AiOutlineEye /> Obserwowani ({myWatches.length})</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-3 container-fluid p-0 m-0'>
                    <div className='col-md-4 pl-0'>
                        <div className="profile-card-small p-4 text-left">
                            <h3>Bio</h3>
                            <p>{profileDescription ? profileDescription : '-'}</p>
                            <h5>Telefon</h5>
                            <p>{phone ? phone : '-'}</p>
                            <h5>Adres</h5>
                            <p>{address ? address : '-'}</p>
                            <h5>WWW</h5>
                            <p>{www ? www : '-'}</p>
                            <h5>Dołączył(a)</h5>
                            <p>{createdAt ? newDatecreatedAt : '-'}</p>
                            <h5>Ostatnia zmiana</h5>
                            <p>{updatedAt ? newDateupdatedAt : '-'}</p>
                        </div>
                        <div className="profile-card-small p-4 text-left mt-3 maini">
                            <h3>Galeria</h3>
                            <div className='row mt-3'>
                                {myGallery.map((myGallery, index) => {
                                    return (
                                        <div className='col-md-4 p-0 main-image' key={index}>
                                            <img className='w-100' src={myGallery.fileName ? 'https://etnoserver.pl/uploads/' + myGallery.fileName : ''} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    </div>
                    <div className='col-md-8 p-0'>
                        <div className='d-flex mb-3'>
                            <button className="btn btn-primary mr-3" onClick={fetchData}> <RiArticleFill /> Opublikowane</button>
                            <button className="btn btn-primary mr-3" onClick={fetchData_2}> <RiArticleFill /> Szkice</button>
                            <button className="btn btn-primary" onClick={fetchData_3}> <RiQuestionLine /> Pytania</button>
                        </div>

                        <div className="profile-card-small p-4 text-left">
                            <h3>{pageTitle}</h3>
                        </div>
                        {UserList.length ?
                            <>
                                {firstS ?
                                    <>
                                        {UserList.map((user, key) => {
                                            return (
                                                <div className="post text-left mt-3" key={key}>
                                                    <button className='imgremove' onClick={() => removemedia(user.id)}>x</button>
                                                    <img src={'https://etnoserver.pl/uploads/' + user.mainImage?.fileName} onError={(event) => event.target.style.display = 'none'} onLoad={(event) => event.target.style.display = ''} alt="" />
                                                    <div className='content p-4'>
                                                        {/* {user.id} */}
                                                        <h4>{user.name}</h4>
                                                        <small>Opublikowano: {moment.utc(user.createdAt).format('DD/MM/YYYY HH:mm:ss')}</small>
                                                        <p>{user.description}</p>
                                                        <Link className="btn btn-primary mt-3" to={`/archive/${user.id}`}>Szczegóły</Link>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                        <ul className="pagination m-0 pt-2 pb-4 me-auto">
                                            <ReactPaginate
                                                previousLabel="Poprzednia"
                                                nextLabel="Następna"
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                breakLabel="..."
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={10}
                                                onPageChange={handlePageClick}
                                                containerClassName="pagination container-fluid ps-3 text-end"
                                                activeClassName="active"
                                            />
                                        </ul>
                                    </>
                                    : ''}
                            </>
                            :
                            <div className="post text-left mt-3 rounded-0">
                                <div className='content p-4'>
                                    <h5>Użytkownik <strong>{name}</strong> {type}</h5>
                                </div>
                            </div>
                        }
                        {secondS ?
                            <>
                                {UserList.map((user, key) => {
                                    return (
                                        <div className="post text-left mt-3" key={key}>
                                            <div className='content p-4'>
                                                <div className='d-flex'>
                                                    <div className=''>
                                                        <img className='profile' src={'https://etnoserver.pl/uploads/' + user.author?.profilePic} onError={(event) => event.target.style.display = 'none'} onLoad={(event) => event.target.style.display = ''} alt="" />
                                                    </div>
                                                    <div className=''>
                                                        <h4>{user.author?.name}</h4>
                                                        <small>Opublikowano: {moment.utc(user.createdAt).format('DD/MM/YYYY HH:mm:ss')}</small>
                                                        <p>{user.content}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </>
                            : ''}
                    </div>
                </div>
            </div>
        </>
    )
};

export default UserInfo;