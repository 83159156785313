import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./templates/header/Layout";
import Home from "./templates/pages/Home";
import Login from "./templates/pages/Login";
import Contact from "./templates/pages/Contact";
import Users from "./templates/pages/Users";
import UserInfo from "./templates/pages/UserInfo";
import UserFriends from "./templates/pages/UserFriends";
import UserFriendsMy from "./templates/pages/UserFriendsMy";
import PlaceSingle from "./templates/pages/PlaceSingle";
import Places from "./templates/pages/Places";
import PlacesEvent from "./templates/pages/PlacesEvent";
import Search from "./templates/pages/Search";
import Report from "./templates/pages/Report";
import NoPage from "./templates/pages/NoPage";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/users/" element={<Users />} />
          <Route path="/places/" element={<Places />} />
          <Route path="/places/events/" element={<PlacesEvent />} />
          <Route path="/users/page/:pagenumber" element={<Users />} />
          <Route path="/places/page/:pagenumber" element={<Places />} />
          <Route path="/places/events/page/:pagenumber" element={<PlacesEvent />} />
          <Route path="/search/s=:query" element={<Search />} />
          <Route path="/user/:id" element={<UserInfo />} />
          <Route path="/user/:id/watches" element={<UserFriends />} />
          <Route path="/user/:id/watches/my" element={<UserFriendsMy />} />
          <Route path="/archive/:id" element={<PlaceSingle />} />
          <Route path="/reports" element={<Report />} />
          <Route path="/*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);