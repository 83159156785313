import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate'
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet'

const Places = () => {

    const [isLoading, setIsLoading] = useState(0);
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(8);
    const [pageCount, setPageCount] = useState(0)
    const [UserList, setUserList] = useState([])


    var pathname = window.location.pathname
    var match = pathname.match(/\/places\/page\/(\d*)/)
    if (match) {
        var x = (match[1] - 1)
    }

    const fetchData = async () => {
        const response = await fetch('https://etnoserver.pl:44444/api/places/type/pub')
        const nbaData = await response.json()
        setPageCount(Math.ceil(nbaData.length / perPage))
        setUserList(nbaData.slice(offset, offset + perPage))
        var pathname = window.location.pathname
        var match = pathname.match(/\/places\/page\/(\d*)/)
        if (match) {
            setOffset((match[1] - 1) * perPage)
        }
        if (nbaData.slice(offset, offset + perPage).length === 0) {
            setOffset((match[1] - 2) * perPage)
            window.history.replaceState(null, '', 'http://' + window.location.hostname + ':' + window.location.port + '/places/page/' + (match[1] - 1));
        }
    }

    useEffect(() => {
        setIsLoading(1)
        fetchData()
    }, [offset])

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setOffset((selectedPage) * perPage)
        window.history.replaceState(null, '', 'http://' + window.location.hostname + ':' + window.location.port + '/places/page/' + (selectedPage + 1));
    }
    return (
        <>
        <Helmet>
        <title>{'Wpisy'}</title>
      </Helmet>
        <div class={isLoading === 0 ?  'lds-hourglass'  : null}></div>
            <div className="d-sm-flex align-items-center">
                <h1 className="h2 mb-0 text-gray-800">Wpisy</h1>
            </div>
            <div className="row ur-flex p-3">
                {UserList.map((place, key) => {
                    return (
                        <div className="place-card col-md-3 p-0" key={key}>
                            <div className="place-header">
                                <img src={place.mainImage?.fileName ? 'https://etnoserver.pl/uploads/' + place.mainImage?.fileName : 'https://citelis.com.mx/assets/images/edificios-independientes/Cover/EIMLM145.png'} alt="" />
                            </div>
                            <div className="place-body mt-0">
                                <div className="name">{place.name ? place.name.length > 20 ? `${place.name.substring(0, 20)}...` : place.name  : 'Unlocalized String'}</div>
                                <div className="intro">
                                    <p>{place.description ? place.description.length > 40 ? `${place.description.substring(0, 40)}...` : ''  : ''}</p>
                                </div>
                                <Link className="btn btn-primary w-100" to={`/archive/${place.id}`}>Szczegóły</Link>
                            </div>
                        </div>
                    )
                })
                }
            </div>
            <ul className="pagination m-0 pt-2 pb-4 me-auto w-100">
                    <ReactPaginate
                        previousLabel="Poprzednia"
                        nextLabel="Następna"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={10}
                        onPageChange={handlePageClick}
                        forcePage={x}
                        containerClassName="pagination container-fluid text-end p-0"
                        activeClassName="active"
                    />
                </ul>
        </>

    );
};

export default Places;