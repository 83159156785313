import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate'
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet'

const Users = () => {
    const [isLoading, setIsLoading] = useState(0);
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(8);
    const [pageCount, setPageCount] = useState(0)
    const [UserList, setUserList] = useState([])

    var pathname = window.location.pathname
    var match = pathname.match(/\/users\/page\/(\d*)/)
    if (match) {
        var x = (match[1] - 1)
    }

    const fetchData = async () => {
        const response = await fetch('https://etnoserver.pl:44444/api/user/list')
        const nbaData = await response.json()
        setPageCount(Math.ceil(nbaData.length / perPage))
        setUserList(nbaData.slice(offset, offset + perPage))
        var pathname = window.location.pathname
        var match = pathname.match(/\/users\/page\/(\d*)/)
        if (match) {
            setOffset((match[1] - 1) * perPage)
        }
        if (nbaData.slice(offset, offset + perPage).length === 0) {
            setOffset((match[1] - 2) * perPage)
            window.history.replaceState(null, '', 'http://' + window.location.hostname + ':' + window.location.port + '/users/page/' + (match[1] - 1));
        }
    }

    useEffect(() => {
        setIsLoading(1)
        fetchData()
    }, [offset])

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setOffset((selectedPage) * perPage)
        window.history.replaceState(null, '', 'http://' + window.location.hostname + ':' + window.location.port + '/users/page/' + (selectedPage + 1));
    }
    return (
        <>
        <Helmet>
                <title>{'Użytkownicy'}</title>
            </Helmet>
        <div class={isLoading === 0 ?  'lds-hourglass'  : null}></div>
            <div className="d-sm-flex align-items-center justify-content-between">
                <h1 className="h2 mb-0 text-gray-800">Użytkownicy</h1>
            </div>
            <div className="row">
                {UserList.map((user, key) => {
                    return (
                        <div className="profile-card col-md-3 p-0" key={key}>
                            <div className="profile-header">
                                <img src={user.profileBigPic?.fileName ? 'https://etnoserver.pl/uploads/' + user.profileBigPic?.fileName : 'https://citelis.com.mx/assets/images/edificios-independientes/Cover/EIMLM145.png'} alt="" />
                            </div>
                            <div className="profile-body">
                                <div className="author-img">
                                    <img src={user.profilePic?.fileName ? 'https://etnoserver.pl/uploads/' + user.profilePic?.fileName : 'https://i2.wp.com/a.slack-edge.com/df10d/img/avatars/ava_0007-192.png?ssl=1'} alt="" />
                                </div>
                                <div className="name">{user.name ? user.name.length > 20 ? `${user.name.substring(0, 20)}...` : user.name  : 'Nieznany użytkownik'}</div>
                                <small>{user.email ? user.email : 'nieznany email'}</small>
                                <div className="intro">
                                    <p>{user.profileDescription ? user.profileDescription.length > 40 ? `${user.profileDescription.substring(0, 40)}...` : ''  : ''}</p>
                                </div>
                                <Link className="btn btn-primary w-100" to={`/user/${user.id}`}>Szczegóły</Link>
                            </div>
                        </div>
                    )
                })
                }

                <ul className="pagination m-0 pt-2 pb-4 me-auto w-100">
                    <ReactPaginate
                        previousLabel="Poprzednia"
                        nextLabel="Następna"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={10}
                        onPageChange={handlePageClick}
                        forcePage={x}
                        containerClassName="pagination container-fluid ps-3 text-end"
                        activeClassName="active"
                    />
                </ul>
            </div>
        </>

    );
};

export default Users;