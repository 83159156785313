import React, { useState, useEffect } from 'react';
import axios from 'axios'
import Swal from 'sweetalert2'
import { MdOutlineEventNote, MdOutlineArticle, MdLabelOutline } from 'react-icons/md';
import moment from 'moment';
import { FiUsers } from 'react-icons/fi';
import { Buffer } from 'buffer';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Helmet } from 'react-helmet';

import Pdf from "react-to-pdf";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
        },
        title: {
            display: false,
        },
    },
};


const style = { position: "absolute", fontSize: "7em", right: "16px", top: "16px", opacity: "0.2", transform: "rotate(25deg)" }

const ref = React.createRef();

const Home = () => {

    const now = new Date();

    const sleep = ms => new Promise(res => setTimeout(res, ms));

    const labels = ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'];

    const [users_stats, setusers_stats] = useState([]);
    const [post_stats, setpost_stats] = useState([]);

    const data = {
        labels,
        datasets: [
            {
                label: 'Wpisy',
                data: post_stats,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Użytkownicy',
                data: users_stats,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };


    const [isLoading, setIsLoading] = useState(0);
    const [TagList, setTagList] = useState([])
    const [profilesl, setprofilesl] = useState([]);
    const [tagsl, settagsl] = useState([]);
    const [pubsl, setpubsl] = useState([]);
    const [eventsl, seteventsl] = useState([]);

    const yourToken = 'secretPhrase55';
    const fetchData = async () => {
        axios.get(`https://etnoserver.pl:44444/api/tags`)
            .then(function (response) {
                setTagList(response.data);

            })
            .catch(function (error) {
            })
    }

    useEffect(() => {

        async function fetchData() {
            setIsLoading(1)
            for (var i = 1; i <= 12; i++) {
                const firstDay = new Date(now.getFullYear(), i - 1, 1);
                const firstDay_c = moment(firstDay).format('YYYY-MM-DD')
                const lastDay = new Date(now.getFullYear(), i, 0);
                const lastDay_c = moment(lastDay).format('YYYY-MM-DD')
                axios.get(`https://etnoserver.pl:44444/api/user/info/data/gte/${firstDay_c}/lte/${lastDay_c}`)
                    .then(function (response) {
                        setusers_stats(users_stats => [...users_stats, response.data.length])
                    })
                    .catch(function (error) {
                    })
                await sleep(1000);
            }
        }
        fetchData();

        async function fetchData_2() {
            setIsLoading(1)
            for (var ic = 1; ic <= 12; ic++) {
                const firstDayz = new Date(now.getFullYear(), ic - 1, 1);
                const firstDay_cz = moment(firstDayz).format('YYYY-MM-DD')
                const lastDayz = new Date(now.getFullYear(), ic, 0);
                const lastDay_cz = moment(lastDayz).format('YYYY-MM-DD')
                axios.get(`https://etnoserver.pl:44444/api/places/info/data/gte/${firstDay_cz}/lte/${lastDay_cz}`)
                    .then(function (response) {
                        // console.log(post_stats)
                        setpost_stats(post_stats => [...post_stats, response.data.length])
                        console.log(post_stats)
                    })
                    .catch(function (error) {
                    })
                await sleep(1000);
            }
        }
        fetchData_2();

        axios.get(`https://etnoserver.pl:44444/api/tags`)
            .then(function (response) {
                setTagList(response.data);

            })
            .catch(function (error) {
            })

        axios.get(`https://etnoserver.pl:44444/api/user/list`)
            .then(function (response) {
                setprofilesl(response.data.length);

            })
            .catch(function (error) {
            })

        axios.get(`https://etnoserver.pl:44444/api/places/type/event`)
            .then(function (response) {
                seteventsl(response.data.length);

            })
            .catch(function (error) {
            })    

        axios.get(`https://etnoserver.pl:44444/api/places`)
            .then(function (response) {
                setpubsl(response.data.length);

            })
            .catch(function (error) {
            })



        axios.get(`https://etnoserver.pl:44444/api/tags`)
            .then(function (response) {
                settagsl(response.data.length);

            })
            .catch(function (error) {
            })
    }, [])

    const token = localStorage.getItem("_jwt");

    const removetag = (id) => {
        Swal.fire({
            icon: 'question',
            title: 'Czy na pewno?',
            text: 'Niniejszym potwierdzasz usunięcie tej etykiety z puli etykiet. Akcja jest nieodwracalna.',
            confirmButtonText: 'Potwierdzam',
            showCancelButton: true,
            cancelButtonText: 'Anuluj',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`https://etnoserver.pl:44444/api/tags/delete/${id}`, {}, {
                    headers: {
                        ['x-access-token']: token,
                    },
                  }).then(function (response) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Etykieta została usunięta z sukcesem',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        fetchData()
                    })
                    .catch(function (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'An Error Occured!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    });
            }
        })
    }


    const addtag = () => {
        Swal.fire({
            icon: 'question',
            title: 'Wpisz nową etykietę',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            confirmButtonText: 'Dodaj',
            showCancelButton: true,
            cancelButtonText: 'Anuluj',
            showLoaderOnConfirm: true,
            preConfirm: (name) => {
                axios.post(`https://etnoserver.pl:44444/api/tags/create/${name}`, {}, {
                    headers: {
                        ['x-access-token']: token,
                    },
                  })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(response.statusText)
                        }
                        return response.json()
                    })
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: 'success',
                    title: 'Zakończono z sukcesem',
                    text: 'Etykieta została dodana z sukcesem',
                    showConfirmButton: false,
                    timer: 1500
                })
                fetchData()
            }
        })

    }

    const ref = React.createRef();
    const optionsa = {
        orientation: 'landscape',
        unit: 'in',
        format: [17, 10]
    };

    return (
        <>
        <Helmet>
          <title>{ 'Strona główna' }</title>
        </Helmet>
            <div className={isLoading === 0 ? 'lds-hourglass' : null}></div>

            <div className="d-sm-flex align-items-center justify-content-between">
                <div>
                    <h1 className="h2 mb-0 text-gray-800">Strona główna</h1>
                    <small>Podsmumowanie</small>
                </div>
            </div>
            <div className="row search">
                <div className='profile-card col-md'>
                    <FiUsers style={style} />
                    <h1>{profilesl}</h1>
                    <h4>Profile</h4>
                </div>
                <div className='profile-card col-md'>
                    <MdOutlineEventNote style={style} />
                    <h1>{eventsl}</h1>
                    <h4>Wydarzenia</h4>
                </div>
                <div className='profile-card col-md'>
                    <MdOutlineArticle style={style} />
                    <h1>{pubsl}</h1>
                    <h4>Publikacje</h4>
                </div>
                <div className='profile-card col-md'>
                    <MdLabelOutline style={style} />
                    <h1>{tagsl}</h1>
                    <h4>Etykiety</h4>
                </div>
            </div>

            <div className='bg-white pt-4 mt-3' ref={ref}>
                <div className='d-flex justify-content-between mb-3'>
                
                    <div>
                        <h1 className="h2 mt-0 text-gray-800">Statystyki {moment(Date(now.getFullYear())).format('YYYY')}</h1>
                        <small>Za cały rok</small>
                    </div>
                    <div>
                        <Pdf targetRef={ref}  options={optionsa} filename={'Statystyki_etnostoria_' + moment(Date(now.getFullYear())).format('YYYY-MM-DD HH:mm:ss') + '.pdf'}>
                            {({ toPdf }) => <button className='btn btn-primary' onClick={toPdf}>Wygeneruj do PDF</button>}
                        </Pdf>
                    </div>
                </div>
                <Line options={options} data={data} />
            </div>
            <div className="d-sm-flex mt-3 align-items-center justify-content-between">
                <h1 className="h2 mb-0 text-gray-800">Etykiety</h1>
            </div>
            <button className='badge badge-primary p-3 mt-2 mr-2 btn' onClick={() => addtag()}>Wpisz nową etykietę</button>
            <div className="row pt-3 pl-3 pe-3 dashed mb-3">
                {TagList.map((tag, key) => {
                    return (
                        <span className="badge badge-primary p-3 mr-2 mb-3" key={key}>{tag.name ? tag.name : 'pusty tag #' + tag.id} <button className='tagremove' onClick={() => removetag(tag.id)}>x</button></span>
                    )
                })
                }

            </div>
        </>

    );
};

export default Home;