import React, { useState, useEffect } from "react";
import { Outlet, NavLink, useNavigate } from "react-router-dom";
import WebFont from 'webfontloader';
import axios from 'axios'

const Layout = () => {
    const [isLoading, setIsLoading] = useState(0);
    const [isauthorized, setauthorized] = useState(false);
    const curruser = JSON.parse(localStorage.getItem('user')) || null;
    const [query, setQuery] = useState('');
    const [dnone, setdnone] = useState(0);
    const [menushow, setMenushow] = useState('navbar-nav sidebar sidebar-dark accordion donone');
    const navigate = useNavigate();
    useEffect(() => {
        if (curruser) {
            axios.get(`https://etnoserver.pl:44444/api/user/${curruser.id}`)
                .then(function (response) {
                    let user = response.data
                    setauthorized(user.role);
                    if (user.roles[0].name === 'mod') {
                    }
                    else {
                        localStorage.removeItem("user");
                        localStorage.removeItem("_jwt");
                        navigate('/login');
                    }
                });
        }
        else if (isauthorized === false && window.location.pathname !== '/login') {
            navigate('/login');
        }
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Source Sans Pro']
            }
        });
    }, []);
    const handleSubmit = event => {
        event.preventDefault();
        setQuery(query);
        setdnone(1);
        navigate('/search/s=' + query);
    }

    const logout = event => {
        event.preventDefault();
        localStorage.removeItem("user");
        localStorage.removeItem("_jwt");
        navigate('/login');
    }

    const menuShow = () => {
        setMenushow(menushow === 'navbar-nav sidebar sidebar-dark accordion donone' ? 'navbar-nav sidebar sidebar-dark accordion doflex' : 'navbar-nav sidebar sidebar-dark accordion donone')
    }

    if (window.location.pathname !== '/login') {
        return (
            <>
                <input name="query" id="query" type="hidden" value={query} onChange={(e) => { setQuery('xs') }} />
                <div id="wrapper">
                    <ul id="accordionSidebar" className={menushow}>
                        <a href="index.html" className="sidebar-brand align-items-center justify-content-center">
                            <img className="w-100" src={process.env.PUBLIC_URL + '/image/logoextm.png'} alt="" />
                        </a>
                        <hr className="sidebar-divider my-0" />
                        <div className="mt-3">
                            <li className="nav-item">
                                <NavLink className="nav-link" to='/' onClick={() => [setdnone(0), setIsLoading(0)]}><i className="fas fa-fw fa-tachometer-alt"></i><span> Kokpit</span></NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to='/users/' onClick={() => [setdnone(0), setIsLoading(0)]}><i className="fas fa-fw fa-users"></i><span> Użytkownicy</span></NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to='/places/' onClick={() => [setdnone(0), setIsLoading(0)]}><i className="fas fa-fw fa-pen"></i><span> Wpisy</span></NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to='/places/events' onClick={() => [setdnone(0), setIsLoading(0)]}><i className="fas fa-fw fa-pen"></i><span> Wydarzenia</span></NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to='/reports' onClick={() => [setdnone(0), setIsLoading(0)]}><i className="fas fa-fw fa-comments"></i><span> Zgłoszenia </span></NavLink>
                            </li>
                        </div>
                    </ul>
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                                <button id="sidebarToggleTop" className="btn btn-link rounded-circle mr-3" onClick={menuShow}><i className="fa fa-bars"></i></button>
                                {dnone === 0 ?
                                    <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search" method="get" onSubmit={handleSubmit}>
                                        <div className="input-group">
                                            <input type="text" placeholder="Wyszukaj osoby, wpisów, wydarzeń" aria-label="Search" aria-describedby="basic-addon2" className="form-control bg-light border-0 small" value={query} onChange={event => setQuery(event.target.value)} />
                                            <div className="input-group-append">
                                                <button type="submit" className="btn btn-primary"><i className="fas fa-search fa-sm"></i></button>
                                            </div>
                                        </div>
                                    </form>
                                    : null}
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item dropdown no-arrow">
                                        <a href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">
                                            <span className="mr-2 d-none d-lg-inline text-gray-600 small">{curruser ? curruser.name : 'Niezalogowany'}</span>
                                            <img src={curruser?.profilePic?.fileName ? 'https://etnoserver.pl/uploads/' + curruser?.profilePic?.fileName : 'https://citelis.com.mx/assets/images/edificios-independientes/Cover/EIMLM145.png'} className="img-profile rounded-circle" />
                                        </a>
                                        <div aria-labelledby="userDropdown" className="dropdown-menu dropdown-menu-right shadow animated--grow-in">
                                            {curruser ?
                                                <NavLink className="dropdown-item" to={`/user/${curruser.id}`}><i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>Profil</NavLink>
                                                : null}
                                            <NavLink className="dropdown-item" to='/login' onClick={logout}><i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>Wyloguj się</NavLink>
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                            <div className="container-fluid">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
    else {
        return (
            <>
                <div className="container-fluid login-c">
                    <Outlet />
                </div>
            </>
        )
    }
};

export default Layout;