import React, { useState } from 'react';
import { useNavigate } from "react-router-dom"
import axios from 'axios'
import { Helmet } from 'react-helmet'

const Login = () => {
  const [isauthorized, setauthorized] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState('');
  const [alert, setAlert] = useState('');
  const navigate = useNavigate();
  

  const Auth = async (e) => {
    e.preventDefault();
    setMsg(null);
    setAlert(null);
    const response = await fetch('https://etnoserver.pl:44444/api/auth/signin', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })

    const responseJson = await response.json()

    if (responseJson.success === true) {
      console.info('Login Successful')
      localStorage.setItem('_jwt', responseJson.jwt)
    }

    
    try {
      await axios.post('https://etnoserver.pl:44444/api/auth/signin', {
        email: email,
        password: password
      })
        .then(function (response) {
          if (response.data) {
            if (response.data.success === false) {
              setMsg(response.data.message);
              setAlert('login_error');
            }
            if (email && password) {
              axios.get(`https://etnoserver.pl:44444/api/user/${response.data.user.id}`)
                .then(function (response) {
                  let user = response.data
                  setauthorized(user.role);
                  console.log(user.role)
                  if(user.role !== null){
                    if (user.roles[0].name === 'mod') {
                      setAlert('login_success');
                      setMsg('Dane zgodne. Za chwilę zostaniesz przeniesiony/a.');
                      localStorage.setItem("user", JSON.stringify(user));
                      setTimeout(function () {
                        navigate("/");
                      }, 3000);
                    }
                    else {
                      setMsg('Nie posiadasz uprawnień do zarządzania.');
                      setAlert('login_error');
                    }
                  }
                  else {
                    setMsg('Nie posiadasz uprawnień do zarządzania.');
                    setAlert('login_error');
                  }

                });
            }
            else {
              setMsg('Nie wypełniono formularza');
              setAlert('login_error');
            }
          }
          return response.data;
        })
    } catch (error) {
      if (error.response) {
        // setMsg(error.response.data.msg);
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>{'Zaloguj się'}</title>
      </Helmet>

      <div className='login col-md-3'>
        <img className="mb-0 w-100 mb-4" src={process.env.PUBLIC_URL + '/image/logoext.png'} alt="" />
        <div className={alert}>	{msg ? msg : null}</div>
        <form onSubmit={Auth} className=" mx-auto p-4 mt-3 rounded" autoComplete="on">
          <h1 className="h3 mb-3 font-weight-normal">Zaloguj się</h1>
          <div className="field">
            <label className="label">Adres email</label>
            <div className="controls">
              <input type="email" className="input form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
          </div>
          <div className="field mt-2">
            <label className="label">Hasło</label>
            <div className="controls">
              <input type="password" className="input form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
          </div>
          <div className="field mt-4">
            <button className="btn btn-lg btn-primary btn-block">Zaloguj się</button>
          </div>
        </form>
        <small className='text-center'>Wersja <strong>0.0.30_dev</strong></small>
      </div>
    </>
  )
};

export default Login;