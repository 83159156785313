import { Helmet } from 'react-helmet'

const NoPage = () => {
    return (
        <>
            <Helmet>
                <title>{'404 NOT FOUND!!!111ONE'}</title>
            </Helmet>
            <h1>404</h1>
            <small>Nie znaleziono</small>
        </>
    );
};

export default NoPage;