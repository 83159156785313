import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'
import qs from 'qs';
import { useParams, Link, useNavigate, useLocation } from "react-router-dom"
import axios from 'axios'
import { MdOutlineEventNote, MdOutlineArticle, MdLabelOutline } from 'react-icons/md';
import { FiUsers } from 'react-icons/fi';
const style = { position: "absolute", fontSize: "7em", right: "16px", top: "16px", opacity: "0.2", transform: "rotate(25deg)" }

const Search = () => {
    const [isLoading, setIsLoading] = useState(0);
    const [queryy, setQuery] = useState(useParams().query);
    const [profiles, setprofiles] = useState([]);
    const [profilesl, setprofilesl] = useState([]);
    const [tags, settags] = useState([]);
    const [tagsl, settagsl] = useState([]);
    const [pubs, setpubs] = useState([]);
    const [pubsl, setpubsl] = useState([]);
    const [events, setevents] = useState([]);
    const [eventsl, seteventsl] = useState([]);


    useEffect(() => {
        setIsLoading(1)
        axios.get(`http://localhost:44444/api/search/${queryy}`)
            .then(function (response) {
                let user = response.data
                setprofiles(user.profiles);
                setprofilesl(user.profiles.length);
                settags(user.tags);
                settagsl(user.tags.length);
                setpubs(user.pubs);
                setpubsl(user.pubs.length);
                setevents(user.events);
                seteventsl(user.events.length);
            })
            .catch(function (error) {
            })
    }, [])

    const handleSubmit = event => {
        event.preventDefault();
        axios.get(`http://localhost:44444/api/search/${queryy}`)
            .then(function (response) {
                let user = response.data
                setprofiles(user.profiles);
                setprofilesl(user.profiles.length);
                settags(user.tags);
                settagsl(user.tags.length);
                setpubs(user.pubs);
                setpubsl(user.pubs.length);
                setevents(user.events);
                seteventsl(user.events.length);
            })
            .catch(function (error) {
            })
    }

    return (
        <>
        <Helmet>
            <title>{'Wyniki wyszukiwania: ' + queryy}</title>
        </Helmet>
        <div class={isLoading === 0 ?  'lds-hourglass'  : null}></div>
            <div className="d-sm-flex align-items-center justify-content-between">
                <h1 className="h2 mb-3 text-gray-800">Wyniki wyszukiwania: <strong>{queryy}</strong></h1>
            </div>
            <div className='profile-cardd col-md-12 p-3'>
                <form onSubmit={handleSubmit}>
                    <label className='col-md-6 text-left'>
                    <p>Wyszukaj</p>
                    <div className="input-group">
                        <input type="text" placeholder="Wyszukaj osoby, wpisów, wydarzeń" aria-label="Search" aria-describedby="basic-addon2" className="form-control bg-light small" value={queryy} onChange={e => setQuery(e.target.value)} name="queryy" />
                        <div className="input-group-append">
                            <button type="submit" className="btn btn-primary"><i className="fas fa-search fa-sm"></i></button>
                        </div>
                    </div>
                    </label>
                </form>
            </div>
            <div className="row search">
                <div className='profile-card col-md'>
                    <FiUsers style={style} />
                    <h1>{profilesl}</h1>
                    <h4>Profile</h4>
                </div>
                <div className='profile-card col-md'>
                    <MdOutlineEventNote style={style} />
                    <h1>{eventsl}</h1>
                    <h4>Wydarzenia</h4>
                </div>
                <div className='profile-card col-md'>
                    <MdOutlineArticle style={style} />
                    <h1>{pubsl}</h1>
                    <h4>Publikacje</h4>
                </div>
                <div className='profile-card col-md'>
                    <MdLabelOutline style={style} />
                    <h1>{tagsl}</h1>
                    <h4>Etykiety</h4>
                </div>
            </div>

            {tagsl > 0 ? 
            <>
            <h1 className="h2 mb-0 mt-3 text-gray-800">Etykiety</h1>
            <div className="row p-3">
                {tags.map((tags, key) => {
                    return (
                        <span className="badge badge-primary p-3 mr-2" key={key}>{tags.name ? tags.name : 'pusty tag #' + tags.id}</span>
                    )
                })
                }
            </div>
            </>
            : null}

            {pubsl > 0 ? 
            <>
            <h1 className="h2 mb-0 mt-3 text-gray-800">Publikacje</h1>
            <div className="row ur-flex p-3">
                {pubs.map((place, key) => {
                    return (
                        <div className="place-card col-md-3 p-0" key={key}>
                            <div className="place-header">
                                <img src={place.mainImage?.fileName ? 'https://etnoserver.pl/uploads/' + place.mainImage?.fileName : 'https://citelis.com.mx/assets/images/edificios-independientes/Cover/EIMLM145.png'} alt="" />
                            </div>
                            <div className="place-body mt-0">
                                <div className="name">{place.name ? place.name.length > 20 ? `${place.name.substring(0, 20)}...` : place.name : 'Unlocalized String'}</div>
                                <div className="intro">
                                    <p>{place.description ? place.description.length > 40 ? `${place.description.substring(0, 40)}...` : '' : ''}</p>
                                </div>
                                <Link className="btn btn-primary w-100" to={`/archive/${place.id}`}>Szczegóły</Link>
                            </div>
                        </div>
                    )
                })
                }
            </div>
            </>
            : null}

            {eventsl > 0 ? 
            <>
            <h1 className="h2 mb-0 mt-3 text-gray-800">Wydarzenia</h1>
            <div className="row ur-flex p-3">
                {events.map((place, key) => {
                    return (
                        <div className="place-card col-md-3 p-0" key={key}>
                            <div className="place-header">
                                <img src={place.mainImage?.fileName ? 'https://etnoserver.pl/uploads/' + place.mainImage?.fileName : 'https://citelis.com.mx/assets/images/edificios-independientes/Cover/EIMLM145.png'} alt="" />
                            </div>
                            <div className="place-body mt-0">
                                <div className="name">{place.name ? place.name.length > 20 ? `${place.name.substring(0, 20)}...` : place.name : 'Unlocalized String'}</div>
                                <div className="intro">
                                    <p>{place.description ? place.description.length > 40 ? `${place.description.substring(0, 40)}...` : '' : ''}</p>
                                </div>
                                <Link className="btn btn-primary w-100" to={`/archive/${place.id}`}>Szczegóły</Link>
                            </div>
                        </div>
                    )
                })
                }
            </div>
            </>
            : null}

            {profilesl > 0 ? 
            <>
            <h1 className="h2 mb-0 mt-3 text-gray-800">Profile</h1>
            <div className="row ur-flex pt-0 p-3">
                {profiles.map((profiles, key) => {
                    return (
                        <div className="profile-card col-md-3 p-0 mt-0" key={key}>
                            <div className="profile-header">
                                <img src={profiles.profileBigPic?.fileName ? 'https://etnoserver.pl/uploads/' + profiles.profileBigPic?.fileName : 'https://citelis.com.mx/assets/images/edificios-independientes/Cover/EIMLM145.png'} alt="" />
                            </div>
                            <div className="profile-body">
                                <div className="author-img">
                                    <img className='profile' src={profiles.profilePic?.fileName ? 'https://etnoserver.pl/uploads/' + profiles.profilePic?.fileName : 'https://i2.wp.com/a.slack-edge.com/df10d/img/avatars/ava_0007-192.png?ssl=1'} onError={(event) => event.target.style.display = 'none'} onLoad={(event) => event.target.style.display = ''} alt="" />
                                </div>
                                <div className="name">{profiles.name ? profiles.name.length > 20 ? `${profiles.name.substring(0, 20)}...` : profiles.name : 'Nieznany użytkownik'}</div>
                                <small>{profiles.email ? profiles.email : 'nieznany email'}</small>
                                <div className="intro">
                                    <p>{profiles.profileDescription ? profiles.profileDescription.length > 40 ? `${profiles.profileDescription.substring(0, 40)}...` : '' : ''}</p>
                                </div>
                                <Link className="btn btn-primary w-100" to={`/user/${profiles.id}`}>Szczegóły</Link>
                            </div>
                        </div>
                    )
                })
                }
            </div>

            </>
            : null}

        </>

    );
};

export default Search;